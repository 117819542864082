import ReactPixel from "react-facebook-pixel";

const options = {
  autoConfig: true, // Set this to false if you want to manually configure
  debug: false, // Set to true to enable debugging in the console
};

export const initializeFacebookPixel = () => {
  ReactPixel.init("1326711728474916", {}, options);
  //   ReactPixel.init("1326711728474916", {}, options);
  ReactPixel.pageView(); // Log a page view event
};
